<template>
  <div class="w3-left-align">
    <i class="fa fa-print flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewPrinter')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <Column>
        <Row>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="printerNameError"
              type="text"
              :label-name="$t('name')"
              :text="printerName"
              @update-text="text => (printerName = text)"
            />
          </div>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="ipOrDomainError"
              type="text"
              :label-name="$t('ipOrDomain')"
              :text="ipDomain"
              @update-text="text => (ipDomain = text)"
            />
          </div>
        </Row>

        <Row>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="portError"
              type="number"
              :is-number="true"
              :label-name="$t('port')"
              :text="port"
              @update-text="text => (port = text)"
            />
          </div>
          <div class="grid-component">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{
              $t("printService")
            }}</label>
            <div class="w3-col w-100pc flow-margin-right sort-select">
              <StandardSelect
                class="w3-border w3-round-medium dropdown-height"
                type="text"
                :items="printerServices"
                :pre-selected-item="printerServices[0]"
                :valueKey="'value'"
                :valueKeyIndex="'key'"
                :callback="setSelectedPrinterService"
              />
            </div>
          </div>
        </Row>
      </Column>
      <Column>
        <Row>
          <div class="form-button">
            <SaveButton
              :function-executed="createExecuted"
              :on-add="
                () => {
                  createNewPrinter();
                }
              "
            />
          </div>

          <div class="form-button">
            <BackButton :on-clicked="() => sideBarStatusEvent('close')" />
          </div>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
import ContentHeader from "@/components/common/ContentHeader.vue";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import Column from "@/components/common/grid/Column.vue";
import Row from "@/components/common/grid/Row.vue";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";
import BackButton from "@/components/common/buttons/BackButton.vue";
import StandardTextBox from "@/components/common/StandardTextBox.vue";
import { mapActions } from "vuex";

export default {
  name: "CreatePrinter",
  components: {
    StandardTextBox,
    BackButton,
    StandardSelect,
    Row,
    Column,
    SaveButton,
    ContentHeader
  },
  emits: ["success", "failure"],
  data() {
    return {
      printerServices: [
        {
          key: "QKEP",
          value: "Custom"
        },
        {
          key: "ZEP",
          value: "Zebra"
        }
      ],
      createExecuted: false,
      printerName: "",
      ipDomain: "",
      port: "",
      selectedPrinterService: "Custom",
      printerNameError: "",
      portError: "",
      ipOrDomainError: ""
    };
  },
  methods: {
    ...mapActions({
      sideBarStatusEvent: "eventHandlers/sideFormStatusEvent"
    }),
    setSelectedPrinterService(printerService) {
      this.selectedPrinterService = printerService.key;
    },
    isNameValid() {
      let printerNameError = "";

      if (this.printerName === "") {
        printerNameError = this.$t("INVALID_TEXTFIELD");
        this.printerNameError = printerNameError;
        return false;
      }

      if (this.printerName.length > 30) {
        printerNameError = this.$t("INVALID_PRINTER_NAME");
        this.printerNameError = printerNameError;
        return false;
      }
      return true;
    },
    isIpOrDomainValid() {
      let ipOrDomainError;

      if (this.ipDomain === "") {
        ipOrDomainError = this.$t("INVALID_TEXTFIELD");
        this.ipOrDomainError = ipOrDomainError;
        return false;
      }
      return true;
    },
    isPortValid() {
      let portError = "";

      if (this.port === "") {
        portError = this.$t("INVALID_TEXTFIELD");
        this.portError = portError;
        return false;
      }

      if (this.port < 0 || this.port > 65535) {
        portError = this.$t("INVALID_PORT");
        this.portError = portError;
        return false;
      }
      return true;
    },
    isInputValid() {
      this.printerNameError = "";
      this.ipOrDomainError = "";
      this.portError = "";
      return (
        this.isNameValid() && this.isIpOrDomainValid() && this.isPortValid()
      );
    },
    createNewPrinter() {
      if (!this.isInputValid()) return;
      this.createExecuted = true;

      const createPrinterRequest = {
        name: this.printerName,
        ipDomain: this.ipDomain,
        port: this.port,
        printService: this.selectedPrinterService
      };

      this.$restClient.printers
        .add(createPrinterRequest)
        .then(response => {
          this.$emit("success", response);
        })
        .catch(err => {
          console.log(err);
          this.createExecuted = false;
          this.$emit("failure", err);
        });
    }
  }
};
</script>

<style scoped></style>
