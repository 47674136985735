<template>
  <div>
    <div class="w3-container">
      <div class="w3-col w3-left-align flow-margin-top">
        <content-header v-bind:title="$t('accountDetails')"></content-header>
      </div>
    </div>

    <div class="w3-container flow-card blue-top w3-large text-color qpp-margin-sides-5pc">

      <div class="w3-col">
        <p class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color">{{ $t('username') }}</label>
          <span class="w3-col w-45pc">{{ user.username }}</span>
        </p>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <p class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color">{{ $t('firstName') }}</label>
          <span class="w3-col w-45pc">{{ user.firstName }}</span>
        </p>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <p class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color">{{ $t('lastName') }}</label>
          <span class="w3-col w-45pc">{{ user.lastName }}</span>
        </p>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <div class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color" style="text-align: left">{{ $t('webhook') }}</label>
          <textarea class="w3-col w-70pc" v-model="user.webhookUrl" rows="2">
        </textarea>
          <div>
            <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                    @click.prevent="saveWebhook">
              <i class="fa fa-save flow-green-active-color">
                <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
              </i>
            </button>
          </div>
        </div>

        <div class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color" style="text-align: left">{{ $t('phone') }}</label>
          <input class="w3-col w-15pc" v-model="user.phone" type="text" pattern="[0-9]+" maxlength="16"/>
          <div class="w3-col w-15pc"> <span class="w3-tooltip">
              <span class="w3-text phone-number-tooltip">
                {{ $t("phone-format") }}</span>
            </span>
            <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-0 w3-margin-right"
                    @click.prevent="savePhone">
              <i class="fa fa-save flow-green-active-color">
                <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
              </i>
            </button>
          </div>
        </div>
        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <div class="w3-col w3-margin">
        <label class="w3-col w-15pc flow-blue-color" style="text-align: left">{{ $t('language') }}</label>
        <div class="w-45pc flex-container">
            <StandardSelect
                :callback="setSelectedLanguage"
                :items="languages"
                :preSelectedItem="preSelectedLanguage()"
                :valueKey="'name'"
                :valueKeyIndex="'id'"
                class="w3-border w3-round-medium w-30pc"
                type="text"
            />
          <div class="w-10pc"></div>
            <SaveButton :on-add="() => {
              updateLanguageSetting();
              updateLanguage();
            }
            "/>
          </div>
        </div>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>
        <p class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color">{{ $t('role') }}</label>
          <span class="w3-col w-45pc">{{ user.role }}</span>
        </p>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <div class="w3-border w3-border-light-gray w3-col w3-center"></div>

        <!--Domain Types -->
        <div style="text-align: left" class="w3-col w3-margin">
          <label class="w3-col w-15pc flow-blue-color">{{ $t('domainAccess') }}</label>
          <div class="w3-col w-70pc">
            <div v-for="domainType in domainTypes" :key="domainType.id">
              <p class="w-45pc">{{ domainType.name }}</p>
              <input style="text-align: right !important" type="checkbox" :disabled="true"
                     :checked="domainType.isChecked">

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import ContentHeader from "../../common/ContentHeader";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";

export default {
  components: {StandardSelect, SaveButton, ContentHeader},
  name: "AccountDetails",
  data() {
    return {
      selectedLanguage: this.$i18n.locale,
      languages: [
        {
          id: "nl",
          name: "Nederlands"
        },
        {
          id: "en",
          name: "English"
        }
      ],
      currentUser: {},
      user: {
        lastname: "",
        firstName: ""
      },
      name: "testNaam",
      domainTypes: [],
      phoneNumber: []
    }
  },
  mounted() {
    this.getCurrentUser();
    this.getUserDetails()
  },
  methods: {
    preSelectedLanguage() {
      for (let i = 0; i < this.languages.length; i++) {
        if (this.languages[i].id === this.$i18n.locale) return this.languages[i];
      }
      return this.languages[0];
    },
    updateLanguageSetting() {
      console.log(this.selectedLanguage);
      this.$i18n.locale = this.selectedLanguage.id;
    },
    setSelectedLanguage(option) {
      this.selectedLanguage = option;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters["users/getCurrentUser"];
    },
    getUserDetails() {
      const app = this;
      const userId = this.currentUser.id;
      app.$restClient.qppUsers.getUser(userId).then(response => {
        const tempData = response.data;
        app.user = tempData;
        app.getUserDomainTypes()
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    getUserDomainTypes() {
      let temp = [];
      const app = this;
      app.$restClient.domainTypes.all().then(response => {
        let domainTypesList = response.data;
        app.$restClient.domainTypes.currentUserDT().then(currentUserResponse => {
          let currentUserDomainTypes = currentUserResponse.data;
          domainTypesList.forEach(entry => {
            let tempDomainType = currentUserDomainTypes.find(x => x.id === entry.id)
            if (tempDomainType != null) {
              entry.isChecked = true;
            }
            temp.push(entry);
          })
          app.domainTypes = temp;
        })

      })
    },
    saveWebhook() {
      const app = this;
      const webhookUrl = app.user.webhookUrl;
      if (webhookUrl == null || webhookUrl === "" || app.$helper.validateUrl(webhookUrl)) {
        app.$restClient.qppUsers.updateWebHook(app.user.id, webhookUrl).then(() => {
          app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
        })
            .catch(err => {
              if (err.response.status === 422) {
                app.$notify.notifyError({title: this.$t("invalidUrl")});
              } else {
                app.$notify.notifyError({title: this.$t("failed")});
              }
            })
      } else {
        app.$notify.notifyError({title: this.$t("invalidUrl")})
      }
    },

    savePhone() {
      // var x = this.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,2})/);
      // console.log(x);
      // this.value = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
      // this.user.phone = this.phone.replace(' ','');
      // if(this.user.phone.length < 10 || this.user.phone.length > 14) return;

      const app = this;
      let phone = app.user.phone;
      phone = phone.replace(/ /g, '');
      if (phone == null || phone === "" || app.$helper.validatePhone(phone)) {
        app.$restClient.qppUsers.updatePhone(app.user.id, phone).then(() => {
          app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
        })
            .catch(err => {
              if (err.response.status === 422) {
                app.$notify.notifyError({title: this.$t("invalidPhone")});
              } else {
                app.$notify.notifyError({title: this.$t("failed")});
              }
            })
      } else {
        app.$notify.notifyError({title: this.$t("invalidPhone")})
      }
    },
    updateLanguage() {
      this.$restClient.qppUsers.updateLanguage(this.currentUser.id, this.selectedLanguage.id).then(() => {
        this.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
      })
          .catch(err => {
            if (err.response.status === 404) {
              this.$notify.notifyError({title: this.$t("userNotFound")});
            } else if (err.response.status === 422) {
              this.$notify.notifyError({title: this.$t("invalidLanguage")});
            } else {
              this.$notify.notifyError({title: this.$t("failed")});
            }
          })
    }
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}

p {
  display: inline-block;
  text-align: left;
  margin-top: 0;
}

button {
  text-align: center;
}

.flex-container {
  display: flex;
  align-items: center; /* Align items vertically in the center */
}
</style>