<template>
  <div class="w3-display-container w3-center w3-margin w3-padding-64">
    <div id="oops-image">
      <img src="../assets/images/oops.svg" alt="">
    </div>
    <div id="oops-message">
      <h1 class="green-text">
        <i class="fas fa-exclamation-triangle"></i>
        Oeps...
      </h1><br><br>
      <div v-if="reason === 'access'">
        <h1 class="blue-text">Je hebt geen toegang tot deze pagina! </h1>

        <br><br>
        <h3 class="green-text">Gelieve een systeem administrator te contacteren.</h3><br>
      </div>
      <div v-else-if="reason === 'license'">
        <h1 class="red-text">Your license has expired! </h1>

        <br><br>
        <h3 class="green-text">Gelieve een systeem administrator te contacteren.</h3><br>
      </div>
      <div v-else-if="reason === 'userlicense'">
        <h1 class="red-text">Geen licentie voor deze gebruiker! </h1>

        <br><br>
        <h3 class="green-text">Gelieve een systeem administrator te contacteren.</h3><br>
      </div>
      <div v-else>
        <h1 class="blue-text">Pagina niet gevonden! </h1>

        <br><br>
      </div>


      <button @click="goHome"
              class="w3-bar-item w3-btn w3-ripple w3-round-medium flow-btn w3-margin flow-blue-bg-color">
        <i class="fa"><span><b class="w3-text-white">Naar de beginpagina</b></span></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PathNotFound",
  computed: {
    reason: function () {
      return this.$route.query.reason;
    }
  },
  methods: {
    goHome: function () {
      this.$router.push({
        name: 'home', params: {
          siteId: this.$store.getters["users/getCurrentUser"].id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>