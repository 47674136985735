<template>
    <span v-if="compareId !== visitId">
        <i class="fa fa-star appointment-priority cursor-pointer w3-large"
           v-bind:class="appointmentHasPriorityProp ? 'active' : 'inactive'"
           @click="changePriority"
           :title="$t('priority')">
        </i>
    </span>
  <span v-else>
        <i class="fa fa-spinner w3-spin w3-large w3-opacity w3-disabled transform-animation">
        </i>
    </span>
</template>

<script>
export default {
  name: "AppointmentPriority",
  components: {},
  props: ['visitId', 'appointmentHasPriority'],
  emits: ['starred'],
  data: function () {
    return {
      compareId: undefined
    }
  },
  computed: {
    appointmentHasPriorityProp: {
      get() {
        return this.appointmentHasPriority
      },
      set(val) {
        this.$emit('update:appointmentHasPriority', val)
      }
    }
  },
  watch: {
    appointmentHasPriority: function (newVal) {
      this.appointmentHasPriorityProp = newVal;
    }
  },
  methods: {
    changePriority: function () {
      const app = this;
      let starred = !app.appointmentHasPriorityProp;
      app.compareId = app.visitId;
      app.$restClient.visits.setVisitPriority(app.visitId, starred).then(function () {
        app.$emit("starred", starred);
        app.compareId = undefined;
      }).catch(function (er) {
        console.log(er);
        app.compareId = undefined;
        app.$notify.notifyError({title: app.$t("failed")});
      });
    }
  }
}
</script>