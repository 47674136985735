<template>
  <button
    class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium"
    @click.prevent="onClicked"
  >
    <i class="fa fa-arrow-left w3-text-red">
      <span
        ><b class="flow-margin-left">{{ $t("back") }}</b></span
      >
    </i>
  </button>
</template>
<script>
export default {
  name: "BackButton",
  props: {
    /**
     * callback function
     */
    onClicked: {
      type: Function,
      required: true
    }
  }
};
</script>
