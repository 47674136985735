class QppUsers {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_BY_ID: '/api/sites/:siteId/users',
            GET_BY_ROLE: '/api/sites/:siteId/users/role',
            POST_WEBHOOK: '/api/sites/:siteId/users/:userId',
            POST_PHONE: '/api/sites/:siteId/users/:userId/phone',
            PUT_LANGUAGE: '/api/users/:userId/language',
            GET_LANGUAGE: '/api/users/:userId/language'
        };
    }

    getUsersByRole(role) {
        return this.api.get(this.EP.GET_BY_ROLE+ '/' + role);
    }

    getKiosks() {
        return this.api.get(this.EP.GET_BY_ROLE+"/kiosk");
    }

    getUser(id) {
        return this.api.get(this.EP.GET_BY_ID + '/' + id);
    }

    updateWebHook(userId,webHook){
        return this.api.put(this.EP.POST_WEBHOOK.replace(':userId',userId),{"webhookUrl":webHook})
    }

    updatePhone(userId,phone){
        return this.api.put(this.EP.POST_PHONE.replace(':userId',userId),{"phone":phone})
    }

    updateLanguage(userId, language) {
        return this.api.put(this.EP.PUT_LANGUAGE.replace(':userId',userId),{"language":language})
    }

    getLanguage(userId) {
        return this.api.get(this.EP.GET_LANGUAGE.replace(':userId',userId));
    }
}

export default QppUsers;