<template>
  <button v-show="!functionExecuted"
    class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-right"
    @click="onAdd"
  >
    <i class="fa fa-save flow-green-active-color">
      <span
        ><b class="flow-margin-left">{{ $t("save") }}</b></span
      >
    </i>
  </button>
  <button v-show="functionExecuted"
    class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium  w3-margin-right"
  >
    <i class="fa fa-save flow-green-active-color">
      <span
        ><b class="flow-margin-left">{{ $t("save") }}</b></span
      >
    </i>
  </button>
</template>

<script>
export default {
  name: "SaveButton",
  props: {
    /**
     * callback function
     */
    onAdd: {
      type: Function,
      required: true,
    },
    functionExecuted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  // data() {
  //   return {
  //     functionFullyExecuted: false
  //   };
  // },
  // watch: {
  //   functionFullyExecuted() {
  //     if (this.onAdd) this.functionFullyExecuted = true;
  //   }
  // }
};
</script>
