<template>
  <div v-if="loaded" class="ongoing">
    <div v-if="!ongoing.currentVisits || ongoing.currentVisits.length === 0">
      <h5 class="zero-bottom w3-animate-opacity w3-center w3-padding">
        <span class="flow-margin-left font-weight-bold w3-margin-top w3-text-deep-orange">
            <i class="fa fa-bullhorn"></i>
            {{ $t('notOngoing') }}
        </span>
      </h5>
    </div>

    <div v-else-if="ongoing && ongoing.currentVisits" class="w3-container w3-center w3-margin-bottom">

      <h5 v-if="ongoing.currentVisits.length > 1" class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
        <i class="fa fa-users flow-green-active-color" aria-hidden="true" :title="$t('group')"
           style="display: inline-block">
        </i>
        <span class="font-weight-bold">
            <p style="display: inline-block; margin-left: 10px">{{
                $t("groupAppointment") + ": " + ongoing.currentVisits.length + " " + $t("persons")
              }}</p>
          </span><br>
        <span class="font-weight-normal">{{ ongoing.groupName }}
          </span>
      </h5>

      <h5 v-else class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
          <span class="font-weight-bold">
            <VisitorNameHeader :props-visitor="ongoing.currentVisits[0].visitor"></VisitorNameHeader>
          </span><br>
        <span class="font-weight-normal">
            ({{ ongoing.currentVisits[0].appointmentDateTime }}<span v-if="ongoing.currentVisits[0].domainTypeName"> - {{
            ongoing.currentVisits[0].domainTypeName
          }}</span>)
          </span>
        <span v-if="ongoing.currentVisits[0].visitTicket"> - <b>{{
            $t('ticket')
          }} :</b>  {{ ongoing.currentVisits[0].visitTicket }}</span>
        <br>
        <i class="fa fa-birthday-cake flow-green-active-color" aria-hidden="true" :title="$t('dob')">
        </i>
        <span class="flow-margin-left">{{
            ongoing.currentVisits[0].visitor.dob ? ongoing.currentVisits[0].visitor.dob : $t('notAvailable')
          }}</span>
      </h5>


      <button
          class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top  w3-margin-right flow-green-bg-other-color w3-text-white"
          @click="recallVisitor">
        {{ $t('recallVisitor') }}
      </button>

      <button
          class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top w3-margin-right  w3-deep-orange"
          @click="holdAppointment">
        {{ $t('holdAppointment') }}
      </button>

      <button class="flow-btn w3-btn w3-border w3-ripple w3-round-medium w3-large w3-margin-top w3-red"
              @click="endVisit">
        {{ $t('endVisit') }}
      </button>

    </div>
  </div>

  <div v-else class="w3-col w3-padding-large w3-center">
    <div>
      <h5 class="flow-blue-color zero-bottom w3-animate-opacity w3-center">
        <span class="flow-margin-left font-weight-bold  w3-margin-top">
            {{ $t('loading') }}
        </span>
        <span class="zero">
          {{ $t('pleaseWait') }}
        </span>
      </h5>
    </div>
  </div>

</template>
<script>
import VisitorNameHeader from "../../common/VisitorNameHeader";

export default {
  name: "OngoingVisit",
  components: {VisitorNameHeader},
  props: ['ongoing', "endVisit", "holdAppointment", "recallVisitor"],
  data() {
    return {
      loaded: true
    }
  }
}

</script>

<style>
.ongoing {
  min-height: 152px;
}

.ongoing .flow-btn {
  min-width: 200px;
}
</style>