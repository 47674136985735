<template>
  <div
      class="w3-container flow-main-content w3-center w3-padding w3-ripple"
  ></div>
  <div
      class="w3-container flow-card blue-top w3-animate-opacity w-80pc flow-desks-content"
  >
    <loading v-bind:dspin="!contentLoaded"></loading>
    <table v-show="contentLoaded" class="w3-table w3-bordered flow-table">
      <thead class="flow-blue-color">
      <tr class="table-header-row">
        <th>{{ $t("name") }}</th>
        <th>{{ $t("printer") }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-show="desks.length === 0">
        <td class="w3-center flow-blue-color" colspan="8">
          <b class="font-size-1pt25em">{{ $t("noDesks") }}</b>
        </td>
      </tr>
      </tbody>

      <tbody v-for="desk in desks" :key="desk">
      <tr v-bind:id="desk.id" :key="desk.id" class="no-hover w3-border-0">
        <td>
          <input
              v-model="desk.name"
              class="w3-input w3-border w3-round-medium input-width"
              type="text"
              v-bind:placeholder="$t('name')"
          />
          <span
              class="w3-text-red qpp-field-error-message"
              v-if="!desk.name"
          >{{ $t("INVALID_NAME") }}</span
          >
        </td>
        <td>
          <StandardSelect
              class="w3-border w3-round-medium dropdown-height"
              type="text"
              :pre-selected-item="preSelectedPrinter(desk.printerId)"
              :items="printers"
              :valueKey="'value'"
              :valueKeyIndex="'key'"
              :callback="
                  u => {
                    desk.printerId = u.key;
                  }
                "
          />
        </td>

        <td class="qpp-inline-buttons qpp-desks">
              <span
                  class="btn flow-btn w3-padding-small w3-round-medium"
                  v-bind:class="
                  showSaveButton(desk) ? 'cursor-pointer' : 'w3-disabled'
                "
                  @click.prevent="saveDesk(desk)"
              >
                <i
                    class="fa fa-check-circle w3-large flow-green-active-color"
                ></i>
                <span
                    class="flow-green-active-color flow-margin-left font-weight-bold w3-large"
                >{{ $t("confirm") }}</span
                >
              </span>

          <span>
                <span
                    class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                    @click="deleteDesk(desk)"
                >
                  <i class="fa fa-trash w3-text-deep-orange"></i>
                  <span
                      class="w3-text-deep-orange flow-margin-left font-weight-bold w3-large"
                  >{{ $t("delete") }}</span
                  >
                </span>
              </span>
        </td>
      </tr>

      <tr class="no-hover w3-border-0">
        <td colspan="5">
          <div v-if="desk.clickedDelete">
                <span class="w3-center w3-col w-100pc w3-margin w3-padding">
                  <span
                      class="w3-large font-weight-bold flow-blue-color flow-margin-right"
                  >{{ $t("delete") }}
                    <span
                        class="w3-large font-weight-bold w3-text-deep-orange"
                    >{{ desk.name }}</span
                    >
                    ?
                  </span>
                  <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      @click.prevent="confirmDeletion(desk)"
                  >
                    <i class="fa fa-trash w3-text-deep-orange"></i>
                    <span
                        class="flow-blue-color flow-margin-left font-weight-bold w3-large"
                    >{{ $t("yes") }}</span
                    >
                  </span>

                  <span
                      class="w3-margin-left cursor-pointer btn flow-btn w3-padding-small w3-round-medium"
                      @click.prevent="cancelDeletion(desk)"
                  >
                    <i class="fa fa-times flow-blue-color"></i>
                    <span
                        class="flow-blue-color flow-margin-left font-weight-bold w3-large"
                    >{{ $t("no") }}</span
                    >
                  </span>
                </span>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Loading from "../../common/Loading";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";

export default {
  name: "DesksList",
  props: ["newDeskAdded"],
  components: { StandardSelect, Loading },

  data() {
    return {
      selectedPrinter: {
        key: "A",
        value: "Printer A"
      },
      contentLoaded: false,
      desks: [],
      printers: [],
      printersWithKey: {},
      masterCopy: []
    };
  },

  mounted() {
    this.getAllDesks();
  },
  created() {
    this.getAllPrinters();
  },
  watch: {
    newDeskAdded() {
      this.getAllDesks();
    }
  },

  methods: {
    getAllDesks() {
      const app = this;
      app.desks = [];
      app.$restClient.desks
        .all()
        .then(response => {
          app.contentLoaded = true;
          let data = response.data;
          if (data.length > 0) {
            app.desks = data;
            data.forEach(function(d) {
              app.manageMasterCopy(d);
            });
          }
        })
        .catch(err => {
          console.log(err);
          app.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    getAllPrinters() {
      this.$restClient.printers
        .all()
        .then(response => {
          let data = response.data;
          if (data.length > 0) {
            this.printers = data.map(printer => ({
              key: printer.printerId,
              value: printer.name + " (" + printer.ipDomain + ")"
            }));
            this.printers.unshift({
              key: null,
              value: this.$t("noPrinter")
            });
            let printersWithKey = {};
            data.forEach(function(printer) {
              printersWithKey[printer.printerId] = {
                key: printer.printerId,
                value: printer.name + " (" + printer.ipDomain + ")"
              };
            });
            printersWithKey[null] = {
              key: null,
              value: this.$t("noPrinter")
            };
            this.printersWithKey = printersWithKey;
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    showSaveButton(_desk) {
      if (_desk.name === "") return false;
      const mp = this.masterCopy[_desk.id];
      if (!mp) {
        return false;
      }

      return mp.name !== _desk.name || mp.printerId !== _desk.printerId;
    },

    saveDesk(desk) {
      if (!this.showSaveButton(desk)) return;

      const app = this;
      app.$restClient.desks
        .update(desk)
        .then(response => {
          // eslint-disable-next-line no-unused-vars
          let data = response.data;
          app.getAllDesks();
          app.$notify.notifySuccess({
            title: this.$t("success"),
            message: this.$t("modified-success")
          });
        })
        .catch(err => {
          console.log(err);
          app.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    deleteDesk(desk) {
      desk.clickedDelete = true;
    },

    confirmDeletion(desk) {
      const app = this;
      // eslint-disable-next-line no-unused-vars
      app.$restClient.desks
        .delete(desk.id)
        .then(() => {
          app.$notify.notifySuccess({
            title: this.$t("success"),
            message: this.$t("modified-success")
          });

          app.getAllDesks();
        })
        .catch(err => {
          console.log(err);
          if (err && err.response.status === 422)
            app.$notify.notifyError({
              title: this.$t("failed"),
              message: this.$t("appointment-exist-error")
            });
          else if (err && err.response.status === 400)
            app.$notify.notifyError({
              title: this.$t("failed"),
              message: this.$t("desk-linked-error")
            });
          else app.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    cancelDeletion(desk) {
      desk.clickedDelete = false;
    },

    manageMasterCopy(_desk) {
      this.masterCopy[_desk.id] = {
        name: _desk.name,
        printerId: _desk.printerId
      };
    },

    preSelectedPrinter(printerId) {
      return this.printersWithKey[printerId];
    }
  }
};
</script>

<style scoped>
.dropdown-height {
  max-height: 41px;
  min-width: 150px;
}

th {
  text-align: left;
}

.table-header-row:hover {
  background-color: white;
}
</style>
