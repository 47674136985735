<template>
  <div class="submenu-section">
    <router-link v-for="item in menuItems"
                 :key="item.name"
                 class="submenu-box" :to="{name : item.route}">

      <div class="submenu-item">
        <div class="flex-row w3-border-blue">
          <div class="w3-left-align">
            <h2 style="padding-left: 5px">{{ $t(item.name) }}</h2>
            <div>{{ $t(item.description) }}</div>
          </div>

          <div class="w3-center flex-center">
            <i class="fa fa-3x flow-blue-color" :class="item.icon"></i>
          </div>
        </div>
      </div>
    </router-link>

  </div>
</template>

<script>
export default {
  name: "SubMenus",
  props: ['menuItems']
}
</script>

<style scoped>

.submenu-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1vw 5vw;
}

.submenu-box {
  padding: 10px;
  width: 33%;
  text-decoration: none;
}

.submenu-item {
  border: 1px solid var(--flow-main-color);
  border-radius: 10px;
  height: 100%;
}

.submenu-item:hover {
  background: #d2dde1;
}

.submenu-item div {
  padding: 5px;
  flex-grow: 1;
}
</style>