import {createRouter, createWebHistory} from 'vue-router';
import Layout from './components/common/Layout.vue';
// import Welcome from "./components/Welcome.vue";
import AccountSetup from "./components/views/AccountSetup.vue";
import Desks from "@/components/views/desks/Desks";
import Domains from "@/components/views/domains/Domains";
import OpeningHours from "@/components/views/opening-hours/OpeningHours";
import Languages from "@/components/views/languages/Languages";
import WaitingRooms from "@/components/views/waiting-rooms/WaitingRooms";
import WalkingInstructions from "@/components/views/walking-instructions/WalkingInstructions";
import StandardTexts from "@/components/views/standard-texts/StandardTexts";
import ListEntry from "@/components/views/aanmelding/ConnectDesk";
import VisitList from "@/components/views/aanmelding/VisitList";
import Logout from "@/components/Logout";
import PathNotFound from "@/components/PathNotFound";
import AccountDetails from "./components/views/user/AccountDetails";
import WaitingRoomScreen from "@/components/views/WaitingRoomScreen";
import Messages from "@/components/views/message-board/Messages.vue";
import Dashboard from "@/components/views/Dashboard.vue";
import Reports from "@/components/views/visitor-report/VisitorReport.vue";
import AdminSettings from "@/components/views/admin-settings/AdminSettings.vue";
import KioskSettings from "@/components/views/kiosk-settings/KioskSettings.vue";
import SiteSettings from "@/components/views/site-settings/SiteSettings.vue";
// import Help from "@/components/views/help/Help.vue";
import Printers from "@/components/views/printers/Printers.vue";

// import Dashboard from './components/views/Dashboard.vue';
//import i18n from "./lang/lang";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/layout", component: Layout, children: [
                {path: "/", alias: ["/welcome", "/dashboard"], component: Dashboard, meta: {NORMAL: true, ADMIN: true}},
                {
                    path: "/sites/:siteId/accountDetails",
                    name: "accountDetails",
                    component: AccountDetails,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/dashboard",
                    name: "dashboard",
                    component: Dashboard,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {   path: "/sites/:siteId/home",
                    name: "home",
                    component: ListEntry,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/deskDetails",
                    name: "deskDetails",
                    component: ListEntry,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/desks/:deskId/users/:userId",
                    name: "visit",
                    component: VisitList,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/sites",
                    name: 'sites',
                    component: ListEntry,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/admin-settings",
                    name: 'admin-settings',
                    component: AdminSettings,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/kiosk-settings",
                    name: 'kiosk-settings',
                    component: KioskSettings,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/site-settings",
                    name: 'site-settings',
                    component: SiteSettings,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/admin-settings/reports",
                    name: 'reports',
                    component: Reports,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/admin-settings/messages",
                    name: 'messages',
                    component: Messages,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/domains",
                    name: 'domains',
                    component: Domains,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/opening-hours",
                    name: 'opening-hours',
                    component: OpeningHours,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/printers",
                    name: 'printers',
                    component: Printers,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/desks",
                    name: 'desks',
                    component: Desks,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/languages",
                    name: 'languages',
                    component: Languages,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/standard-texts",
                    name: 'standard-texts',
                    component: StandardTexts,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/languages",
                    name: 'languages',
                    component: Languages,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/waiting-rooms",
                    name: 'waiting-rooms',
                    component: WaitingRooms,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/instructions",
                    name: 'instructions',
                    component: WalkingInstructions,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/account_setup",
                    name: 'account_setup',
                    component: AccountSetup,
                    meta: {NORMAL: false, ADMIN: true}
                },
				// {
				// 	path: "/sites/:siteId/help",
				// 	name: "help",
				// 	component: Help,
				// 	meta: {NORMAL: true, ADMIN: true}
				// },
                {
                    path: "/sites/:siteId/logout",
                    name: "logout",
                    component: Logout,
                    meta: {NORMAL: true, ADMIN: true}
                }
            ]
        },

        {
            path: '/:pathMatch(.*)*',
            component: PathNotFound,
            query: {reason: 'other'},
            meta: {NORMAL: true, ADMIN: true}
        },
        {
            path: '/screen/:screenId',
            name: "waitingRoomScreen",
            component: WaitingRoomScreen,
            meta: {NORMAL: true, ADMIN: true}
        },
        {
            path: '/problem',
            component: PathNotFound,
            meta: {NORMAL: true, ADMIN: true}
        }
    ],
});
export default router;
